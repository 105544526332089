<template>
  <tfoms-panel title="Подразделения" small v-bind="$attrs">
    <div
      class="analysis-tfoms_partition-table analysis-tfoms__table analysis-tfoms__base-table"
    >
      <base-tree-table
        :items="data"
        :headers="this['header' + type]"
        hide-default-footer
        notShowSelect
        tree-table-contents
        small
        :item-class="
          (item) => (value && item.Id === value.Id ? 'table-item--active' : '')
        "
        @click:row="
          $emit('input', value && value.Id === $event.Id ? null : $event)
        "
        :exclude-settings-actions="['table', 'group']"
      >
      </base-tree-table>
    </div>
  </tfoms-panel>
</template>
<script>
import BaseTreeTable from "@/components/base/BaseTreeTable";

export default {
  components: {
    BaseTreeTable,
    tfomsPanel: () => import("@/components/analysis/panel.vue"),
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
      default: 1,
    },
    isNoPayment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      header1: [
        { text: "Подразделение", value: "Name" },
        {
          text: "Объемы",
          value: "VolumeValue",
          dataType: "string",
          align: "center",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
      ],
      header2: [
        { text: "Подразделение", value: "Name" },
        {
          text: "Неоплата",
          value: "NonPaymentValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: "Штрафы",
          value: "FineValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
      ],
      header3: [
        { text: "Подразделение", value: "Name" },
        {
          text: "Объемы",
          value: "VolumeValue",
          dataType: "string",
          align: "center",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: "Фин. обеспечение",
          value: "FinancialValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: "Неоплата",
          value: "NonPaymentValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: "Штрафы",
          value: "FineValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
      ],
      header4: [
        { text: "Подразделение", value: "Name" },
        {
          text: "Сумма",
          value: "FinancialValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
      ],
      header5: [
        { text: "Подразделение", value: "Name" },
        {
          text: "Неоплата",
          value: "NonPaymentValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.analysis-tfoms_partition-table {
  .v-data-table table tr td {
    cursor: pointer;
  }
}
</style>
